import Skeleton from '@mui/material/Skeleton';
import React, {useState, useRef, useEffect} from "react";

export const YoutubeIframe = ({ imgSrc, index }) => {
  const [showVideo, setShowVideo] = useState(false);

  const youTubeRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setShowVideo(entry.isIntersecting)
    })
    observer.observe(youTubeRef.current)
  }, [youTubeRef])

  return (
    <div className="containerYouTubeIndex" ref={youTubeRef} key={index}>
      {showVideo ? (
        <iframe
          title="TMMVideo"
          className="responsive-iframeYouTubeIndex"
          width="700"
          height="394"
          src={imgSrc.src}
          // frameBorder="1" -- deprecated
          loading="lazy"
          allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        />
      ) : (
        <Skeleton
          variant="rectangular"
          className="responsive-iframeYouTubeIndex"
          width={900}
          height={394}
        />
      )}
    </div>
  );
};
