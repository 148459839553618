import { IconMaker } from 'components/common/IconMaker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import { YoutubeIframe } from './YoutubeIframe';
import useScreenSize from 'components/customHooks/useScreenSize';
import { srcArray } from 'utils/constants';

const YoutubeContainer = () => {
  const screenLayoutType = useScreenSize();

  return (
    <div className="youtubeContainer">
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          style={{
            width: '100%',
          }}
        >
          <Box mb="1rem">
            <Carousel
              fullHeightHover={false}
              indicatorIconButtonProps={{
                style: {
                  width: 10,
                  height: 10,
                  backgroundColor: "#D7E9E5",
                  margin: '3px',
                  marginTop: '35px',
                },
                autoPlay: false,
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  backgroundColor: '#2eb790',
                  margin: '3px',
                },
              }}
              animation="slide"
              NavButton={({ onClick, next, prev }) => {
                return (
                  <Button
                    onClick={onClick}
                    style={{
                      top: '50%',
                      backgroundColor: '#2eb790',
                      display:
                        screenLayoutType === 'largeScreen' ? 'flex' : 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {next && (
                      <IconMaker name="next-youtube-carousel" alt="next icon" />
                    )}
                    {prev && (
                      <IconMaker name="back-youtube-carousel" alt="back icon" />
                    )}
                  </Button>
                );
              }}
              IndicatorIcon={
                <span
                  style={{
                    padding: '8px',
                  }}
                />
              }
              swipe={true}
              autoPlay={false}
            >
              {srcArray.map((imgSrc, i) => (
                <YoutubeIframe index={i} key={i} imgSrc={imgSrc} />
              ))}
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default YoutubeContainer;
